
import { getRole } from '@/api/role';
import { getInterestsList } from '@/api/interests';
import {
  checkUserId,
  checkNickName,
  joinUser,
  getUserInfo,
  updateUser,
  updatePasswordUser,
  checkPhone,
  getRelationList,
} from '@/api/user';
import {
  IInterests,
  IRole,
  IUserInterests,
  IUserJoin,
} from '@/types';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { VueDaumPostcode } from 'vue-daum-postcode';
import { UserModule } from '@/store/user';
import { getFile, uploadFile } from '@/api/attachedFile';
import { getEncData } from '@/api/nice';
import { IAttachedFile } from '@/types/attachedFile';
import { NiceModule } from '@/store/nice';

@Component({
  name: 'UserJoin',
  components: {
    VueDaumPostcode,
  },
})
export default class extends Vue {
  mounted() {
    if (UserModule.token) {
      if (this.$route.params.roleCode) {
        if (this.$route.name === 'AppUserJoin') this.$router.push({ name: 'AppMain' });
        else this.$router.push({ name: 'Index' });
      } else {
        this.$validator.pause();
        getUserInfo().then((res) => {
          this.formData = {
            ...this.formData,
            ...res.data,
          };
          this.isUpdate = true;
          this.passwordVisible = false;
          this.logoFile = res.data.logoFile;
          if (res.data.companyGradeFileUid) {
            getFile(res.data.companyGradeFileUid).then((response) => {
              this.companyGradeFile = response.data;
            });
          }
          res.data.roles.forEach((r: string) => {
            getRole(r).then((response: any) => {
              this.role = response.data;
            });
            getInterestsList(r).then((response) => {
              this.interestsList = this.interestsList.concat(response.data);
              this.getUseRelation();
            });
          });
          setTimeout(() => {
            this.$validator.resume();
          }, 1000);
        });
      }
    } else {
      getEncData().then((res) => {
        this.encData = res.data;
      });
      getRole(this.$route.params.roleCode).then((res: any) => {
        this.role = res.data;
        this.formData.roleCode = res.data.roleCode;
      }).catch(() => {
        if (this.$route.name === 'Mypage' || this.$route.name === 'UserJoin') this.$router.push({ name: 'JoinTerms' });
        else this.$router.push({ name: 'AppJoinTerms' });
      });
      getInterestsList(this.$route.params.roleCode).then((res) => {
        this.interestsList = res.data;
      });
    }
  }

  @Watch('formData.userId')
  private handleChangeUserId() {
    this.emailCheckState = 0;
  }

  @Watch('formData.nickName')
  private handleChangeNickName() {
    this.nickNameCheckState = 0;
  }

  @Watch('formData.interestsList')
  private handleChangeInterestsList() {
    if (this.interestsList.length > 0) this.getUseRelation();
  }

  private role: IRole | null = null;

  private passwordCheck = '';

  private emailCheckState = 0;

  private nickNameCheckState = 0;

  private passwordVisible = true;

  private isUpdate = false;

  private relationVisible = false;

  private relationList: any[] = [];

  private formData: IUserJoin = {
    userId: '',
    userPassword: '',
    nickName: '',
    email: '',
    actualName: '',
    phone: '',
    postCode: '',
    address: '',
    addressDetail: '',
    roleCode: '',
    companyName: '',
    companyRegisterNum: '',
    companyGrade: '',
    companyGradeFileUid: '',
    companyTel: '',
    homepage: '',
    fax: '',
    logoFileUid: '',
    manager: '',
    introduce: '',
    interestsList: [],
    roomCount: 0,
    rentMngAmt: 0,
    rentStart: new Date(),
    rentEnd: new Date(),
    relationUserUid: null,
  };

  private logoFile: IAttachedFile | null = null;

  private companyGradeFile: IAttachedFile | null = null;

  private interestsList: IInterests[] = [];

  private selectedInterests: string[] = [];

  private encData = '';

  private gradeList = ['AAA', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-',
    'BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'CCC+', 'CCC', 'CCC-', 'CC', 'C', 'D',
  ];

  private handleClickSave() {
    if (!this.isUpdate) {
      this.formData.actualName = (document.getElementById('actualName') as any).value;
      this.formData.phone = (document.getElementById('phone') as any).value;
    }
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        if (this.isUpdate) {
          if (this.passwordVisible) {
            updatePasswordUser(this.formData).then(() => {
              alert('회원 정보 수정에 성공했습니다.');
              this.$router.go(0);
            }).catch(() => {
              alert('회원 정보 수정에 실패했습니다.');
            });
          } else {
            updateUser(this.formData).then(() => {
              alert('회원 정보 수정에 성공했습니다.');
              this.$router.go(0);
            }).catch(() => {
              alert('회원 정보 수정에 실패했습니다.');
            });
          }
        } else if (this.emailCheckState === 1 && this.nickNameCheckState === 1) {
          joinUser(this.formData).then(() => {
            if (this.role != null && this.role.joinAccessState) {
              alert('가입승인 후 로그인 가능합니다.');
              if (this.$route.name === 'AppUserJoin') this.$router.push({ name: 'AppMain' });
              else this.$router.push({ name: 'Index' });
            } else {
              UserModule.Login({ username: this.formData.userId, password: this.formData.userPassword }).then(() => {
                if (this.$route.name === 'AppUserJoin') {
                  alert('회원가입에 성공했습니다.');
                  this.$router.push({ name: 'AppMain' });
                } else {
                  this.$router.push({ name: 'JoinSuccess' });
                }
              });
            }
          });
        }
      } else {
        /* eslint-disable */
        const inputName = this.$validator.errors.items[0].field;
        document.getElementsByName(inputName)[0].focus();
        /* eslint-enable */
      }
    });
  }

  private handleClickEmailCheck() {
    this.$validator.validate('email').then((valid: boolean) => {
      if (valid) {
        checkUserId(this.formData.userId).then((res) => {
          if (!res.data) this.emailCheckState = 1;
          else this.emailCheckState = 2;
        });
      }
    });
  }

  private handleClickNickNameCheck() {
    this.$validator.validate('nickName').then((valid: boolean) => {
      if (valid) {
        checkNickName(this.formData.nickName).then((res) => {
          if (!res.data) this.nickNameCheckState = 1;
          else this.nickNameCheckState = 2;
        });
      }
    });
  }

  private handleClickPostCode() {
    this.$modal.show('postcode');
  }

  private handleComplete(result: any) {
    this.formData.address = result.address;
    this.formData.postCode = result.zonecode;
    this.$modal.hide('postcode');
  }

  private handleChangePassword() {
    this.passwordVisible = true;
  }

  private handleChangeFile(e: any, type: string) {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 10) {
      alert('최대 10MB 까지 업로드 가능합니다.');
      return;
    }
    if (type === 'logo') {
      if (!file.type.startsWith('image')) {
        alert('이미지 형식만 업로드 가능합니다.');
        (this.$refs.logoFile as HTMLInputElement).value = '';
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      uploadFile('client/user', formData).then((res) => {
        this.logoFile = res.data;
        this.formData.logoFileUid = res.data.uid;
      });
    }
    if (type === 'grade') {
      if (!file.name.endsWith('pdf')) {
        alert('pdf 형식만 업로드 가능합니다.');
        (this.$refs.companyGradeFile as HTMLInputElement).value = '';
        return;
      }
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      uploadFile('client/user', formData).then((res) => {
        this.companyGradeFile = res.data;
        this.formData.companyGradeFileUid = res.data.uid;
      });
    }
  }

  private handleClickRemoveFile(type: string) {
    if (type === 'logo') {
      this.logoFile = null;
      this.formData.logoFileUid = '';
    }
    if (type === 'grade') {
      this.companyGradeFile = null;
      this.formData.companyGradeFileUid = '';
    }
  }

  private handleNicePopup() {
    const nicePopup = window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
    (this.$refs.niceForm as HTMLFormElement).action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    (this.$refs.niceForm as HTMLFormElement).target = 'popupChk';
    (this.$refs.niceForm as HTMLFormElement).submit();
    const loop = setInterval(() => {
      if (nicePopup && nicePopup.closed) {
        clearInterval(loop);
        if ((document.getElementById('actualName') as any).value && (document.getElementById('phone') as any).value) {
          this.formData.actualName = (document.getElementById('actualName') as any).value;
          this.formData.phone = (document.getElementById('phone') as any).value;
          checkPhone(this.formData.phone).then((res) => {
            if (res.data) {
              alert('이미 가입된 사용자입니다.');
              if (this.$route.name === 'AppUserJoin') this.$router.push({ name: 'AppLogin' });
              else this.$router.push({ name: 'Login' });
            }
          });
        }
      }
    }, 400);
  }

  private getUseRelation() {
    this.relationVisible = false;
    const relationUidList: string[] = [];
    this.formData.interestsList.forEach((uid) => {
      const index = this.interestsList.findIndex((i) => i.uid === uid);
      if (index > -1 && this.interestsList[index].relationUid) {
        this.relationVisible = true;
        relationUidList.push(this.interestsList[index].relationUid);
      }
    });
    if (relationUidList.length > 0) {
      const search = {
        interestsList: relationUidList.join(','),
      };
      getRelationList(search).then((res) => {
        this.relationList = res.data;
      });
    } else {
      this.formData.relationUserUid = null;
    }
  }

  private convertLocalDateTime() {
    this.formData.rentStart = new Date(this.formData.rentStart);
  }
}
